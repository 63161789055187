export default function sortData(data, sortValue, sortDirection, type = null) {
    return data.sort((a, b) => {
        if (type === 'date') {
            let dateA = a[sortValue].split('-');
            let dateB = b[sortValue].split('-');
            if (sortDirection === 'up') {
                return new Date(dateA[2], dateA[1], dateA[0]) - new Date(dateB[2], dateB[1], dateB[0]);
            } else {
                return new Date(dateB[2], dateB[1], dateB[0]) - new Date(dateA[2], dateA[1], dateA[0]);
            }
        } else if (typeof a[sortValue] === "string") {
            if (sortDirection === 'up') {
                return a[sortValue].localeCompare(b[sortValue]);
            } else {
                return b[sortValue].localeCompare(a[sortValue]);
            }
        } else {
            if (sortDirection === 'up') {
                return a[sortValue] - b[sortValue];
            } else {
                return b[sortValue] - a[sortValue];
            }
        }
    });
}