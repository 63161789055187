<template>
  <div class="view-container">
    <div class="card staff-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Заказы</div>
        <div v-if="getRight('createOrders')" class="tools">
          <router-link to="/orders/create" class="btn btn-accent"
            >Создать</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <orders-table />
      </div>
    </div>
  </div>
</template>

<script>
import useCheckRights from "../../mixins/useCheckRights";
import OrdersTable from "../../feature/order/display-orders/OrdersTable.vue";
export default {
  components: { OrdersTable },
  name: "orders-page",
  setup() {
    const { getRight } = useCheckRights();

    return {
      getRight,
    };
  },
};
</script>

<style>
</style>